
.App {
  text-align: center;
}
.App-header {
  height: 100vh;
  display: flex;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url('./background/transition.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -300px;
  background-color: #e6e6e6;

}
.Greeting{
  min-height: 100vh;
  width: 50%;
}
.Greeting-cont{
  width: 40%;
  position: relative;
  top: 35%;
  left: 20%;
}
.Art-panel{
  min-height: 100vh;
  width: 50%;
  

}
.Art-panel-cont{
  width: 40%;
  position: relative;
  top: 15%;
  right: 20%;
}
.About{
  height: 60vh;
  font-size: calc(10px + 2vmin);
  color:black;
  background-image: url("./background/about.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -200px;
}
.About-paragraph{
  width: 50%;
  padding-left: 25%;
}
.About h2{
  margin:0;
}
.Team{
  padding-bottom: 5%;
  background-color: black;
  color:white;
}
.Team img{
  border: 5px solid black;
  border-radius: 10px;
}
.Team h1{
  margin-top: 0;
}
.Header{
  background-color: black;
  padding-bottom: 1%;
}






