.container{
    width:500px;
    border:black 5px solid;
    margin-left: 50%;
    overflow: hidden;
    border-radius: 10px;
}
.slides{
    width:1500px;
    display:flex;
    transition: 2s;
}
.slides div{
    background-color: black;
}
img{
    width: 500px;
    height: 500px;
}